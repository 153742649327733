import React, { useEffect, useState } from "react"

import VideoModal from "../modal/VideoModal"

import grid from "../../scss/flexboxgrid.module.scss"
import view from "./videoparagraph.module.scss"
import theme from "../../scss/theme.module.scss"

import { Link } from "gatsby";
function VideoParagraphComponent({ data }) {
  const { videoLink, primaryBtnLink, secondaryBtnLink, playVideoIcon } = data
  const { primaryBtnText, secondaryBtnText } = data
  const { title, description, paragraph } = data
  const { videolocation, videoLinkPreview } = data

  const [modalVisible, toggleModal] = useState(false)

  const ComponentID = primaryBtnText.replace(/\s+/g, '-').toLowerCase();



  const styleDispatch = {
    top: { "flex-direction": "column" },
    bottom: { "flex-direction": "column-reverse" },
    right: { "flex-direction": "row-reverse" },
    left: { "flex-direction": "row" }
  }

  const styleDispatcher = (orientation) => {
    if (Object.keys(styleDispatch).indexOf(orientation) > -1) {
      return styleDispatch[orientation]
    }
  }

  const renderImage = () => {
    return (
      <div className={view["video-wrap"]} onClick={() => toggleModal(!modalVisible)}>
        <img alt="Lotus Headlight" className={view["video"]} src={videoLinkPreview} width={847} height={476} />
        <div className={`${view["pulse-btn"]}`}>
          { playVideoIcon && playVideoIcon.length > 0 ? 
            <img src={playVideoIcon} className={view["play-icon"]} /> : 
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="31" viewBox="0 0 15 31"><g><g transform="rotate(90 7.5 15.5)"><path fill="#062e28" d="M7.258 8.09l14.919 14.9H-7.66z"></path></g></g></svg>
          }
        </div>
      </div>
    )
  }

  const renderParagraph = () => {
    return (
      <>
        <h1>
          <h1 className={view["video-title"]}>{title}</h1>
          <p className={view["video-description"]}>{description}</p>
        </h1>
        <div className={view["video-paragraph"]} dangerouslySetInnerHTML={{ __html: paragraph }} />
        <div className={view["apply-btn-cont"]}>
          {(primaryBtnText.length > 0) &&
          <Link className={`${grid["col-md-6"]} ${view["apply-btn"]}`} to={primaryBtnLink}>{primaryBtnText}</Link>
          }
          {(secondaryBtnText.length > 0) &&
          <Link className={`${grid["col-md-6"]} ${view["apply-btn"]}`} to={secondaryBtnLink}>{secondaryBtnText}</Link>
          }
        </div>
      </>
    )
  }
  return (
    <>
      <VideoModal visible={modalVisible} closeModal={() => toggleModal(!modalVisible)}>
        <iframe src={videoLink}
          width="560" height="315" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="">
        </iframe>
      </VideoModal>
      <div id={ComponentID} className={view["img-para-wrap"]}>
        <div className={view["content-wrap"]} style={styleDispatcher(videolocation)}>
          <div className={view["img-container"]}>{renderImage()}</div>
          <div className={view["p-container"]}>{renderParagraph()}</div>
        </div>
      </div>
    </>
  )
}

VideoParagraphComponent.defaultProps = {
  data: {
    videoLink: "https://www.youtube.com/embed/rokGy0huYEA",
    videolocation: 'right',
    title: 'JOIN THE LOTUS',
    description: 'PALM BEACH FAMILY',
    paragraph: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, ',
    primaryBtnText: 'About Us',
    primaryBtnLink: '/about',
    secondaryBtnText: 'Service',
    secondaryBtnLink: '/service'
  }
}

export default VideoParagraphComponent